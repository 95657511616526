table {
    table-layout: fixed;
    width: 100%;
}

.table th, tr {
    text-align: left;
    vertical-align: middle;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 600px) {
    .chord-sheet-list {
        font-size: 0.9em; /* 모바일에서 폰트 크기 줄이기 */
    }
}
