.chord-sheet {
    font-family: Arial, sans-serif;
    font-size: 16px; /* 기본 폰트 크기 */
    line-height: 1.4; /* 줄 간격 조정 */
}

.verse,
.chorus,
.bridge {
    padding: 8px;
    border-radius: 6px; /* 둥근 모서리 */
}

.row {
    display: flex;
    flex-wrap: wrap; /* 필요 시 줄 바꿈 허용 */
}

.column {
    flex: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 텍스트를 왼쪽 정렬 */
    padding-left: 0!important;
    padding-right: 0!important;
}

.chord {
    font-weight: bold;
    white-space: nowrap; /* 텍스트가 한 줄로 유지 */
    min-height: 1.4em; /* 코드가 없는 경우에도 공간 확보 */
    margin-bottom: 3px; /* 코드와 가사 간격 */
    margin-right: 6px;
}

.image-chord {
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 40px;
}

.image-chord svg {
    width: 100%; /* SVG가 부모 요소에 맞게 크기를 조정하도록 설정 */
    height: 100%; /* SVG가 부모 요소에 맞게 크기를 조정하도록 설정 */
    display: block; /* SVG 요소에 여백이 없도록 설정 */
}

.image-chord--text {
    font-weight: bold;
    white-space: nowrap; /* 텍스트가 한 줄로 유지 */
    margin-right: 6px;
    height: 40px;
    display: inline-block;
}

.image-chord--empty {
    height: 40px;
}

.lyrics {
    text-align: left;
    font-weight: bold;
    white-space: nowrap; /* 텍스트가 한 줄로 유지 */
}

@media (max-width: 600px) {
    .chord-sheet {
        font-size: 0.6rem;
    }
    .chord,
    .image-chord--text {
        font-size: 0.8rem;
    }
    .bass-chord-text {
        font-size: 0.4rem;
    }
    h5 {
        font-size: 1rem;
    }
}

@media (min-width: 768px) {
    .chord-sheet {
        font-size: 1rem;
    }
    .chord {
        font-size: 1.1rem;
    }
    .bass-chord-text {
        font-size: 0.5rem;
    }
    .image-chord--text {
        font-size: 1rem;
    }
    h5 {
        font-size: 1.3rem;
    }
}

@media (min-width: 992px) {
    .chord-sheet {
        font-size: 1.1rem;
    }
    .chord {
        font-size: 1.3rem;
    }
    .bass-chord-text {
        font-size: 0.6rem;
    }
    .image-chord--text {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1.4rem;
    }
}

@media (min-width: 1200px) {
    .chord-sheet {
        font-size: 1.25rem;
    }
    .chord {
        font-size: 1.5rem;
    }
    .bass-chord-text {
        font-size: 0.7rem;
    }
    .image-chord--text {
        font-size: 1.3rem;
    }
    h5 {
        font-size: 1.6rem;
    }
}
