.auto-scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent; /* 완전 투명 */
    border: 1px solid #BBBBBB; /* 모든 배경에서 잘 보이는 회색 테두리 */
    color: #BBBBBB;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}
