.chord-sheet.dark {
    background-color: #333333;
    color: white;
}

.chord-sheet.dark .chord {
    color: lightgreen;
}

.chord-sheet.dark .lyrics {
    color: white;
}

.chord-sheet.dark .image-chord {
    filter: invert(50%) brightness(2) sepia(1) hue-rotate(50deg) saturate(10);
}

.chord-sheet.dark .image-chord--text {
    color: lightgreen;
}

/* 기타 다크 테마 스타일 */
