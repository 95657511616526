/* 기존 스타일 */
.custom-container {
    max-width: 100%!important;
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 576px) {
    .custom-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    .custom-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .custom-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 1200px) {
    .custom-container {
        padding-left: 0;
        padding-right: 0;
    }
}

/* 새로운 스타일 추가 */
body, html, .custom-container {
    margin: 0;
    padding: 0;
    width: 100%;
}

.row, .col-auto {
    margin: 0;
    padding: 0;
}

.btn-group {
    margin: 0;
    padding: 0;
}

