.chord-sheet.autumn {
    background-color: #FAF3E0;
    color: #5C4033;
}

/*.chord-sheet.autumn .verse {*/
/*    background-color: rgba(255, 235, 180, 0.2);*/
/*}*/

/*.chord-sheet.autumn .chorus {*/
/*    background-color: rgba(255, 213, 165, 0.2);*/
/*}*/

/*.chord-sheet.autumn .bridge {*/
/*    background-color: rgba(217, 232, 245, 0.2);*/
/*}*/

.chord-sheet.autumn .chord {
    color: #8B4513;
}

.chord-sheet.autumn .image-chord--text {
    color: #8B4513;
}

.chord-sheet.autumn .lyrics {
    color: black;
}

.chord-sheet.autumn .image-chord {
    filter: invert(22%) sepia(32%) saturate(677%) hue-rotate(335deg) brightness(97%) contrast(82%);
}

/* 기타 가을 테마 스타일 */
