.image-chord--modal {
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 1000; /* 다른 요소들 위에 나타나도록 설정 */
    pointer-events: none; /* 모달에 마우스 이벤트가 걸리지 않도록 설정 */
}

.image-chord--modal svg {
    width: 40px;
    height: 50px;
    display: block;
}