.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 40px; /* 상단 여백 추가 */
}

.bom {
    color: darkgreen;
    font-size: 24px;
    font-weight: bold;
}

.ione {
    color: lightpink;
    font-size: 24px;
    font-weight: bold;
}

.update-date {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 14px;
    color: gray;
}

.notice-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.notice-list li {
    background-color: #f8f9fa;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
}

.notice-number {
    font-weight: bold;
    margin-right: 10px;
}

.notice-content {
    flex-grow: 1;
}

.notice-content p {
    margin: 0;
}

.notice-content img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 5px;
}

/* 새로운 공지사항 강조 스타일 */
.notice-list .new-notice {
    background-color: #f0f8ff; /* 새로운 공지사항 배경색 (아주 연한 파란색) */
    color: #2c3e50; /* 텍스트 색상 */
    border-left: 5px solid #2c3e50; /* 왼쪽 테두리 강조 */
    padding-left: 10px; /* 패딩 추가 */
}

.notice-list .new-notice .notice-number {
    color: #ff6347; /* faStar 아이콘 색상 (밝은 빨간색) */
}
