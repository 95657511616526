.chord-sheet.spring {
    background-color: #FFF0F5; /* 연한 핑크 계열, 벚꽃의 부드러운 느낌 */
    color: #4B2E1E; /* 따뜻한 짙은 브라운, 기본 텍스트 색상 */
}

.chord-sheet.spring .chord {
    color: #D81B60; /* 딥 핑크, 코드 강조 */
}

.chord-sheet.spring .image-chord--text {
    color: #D81B60; /* 코드와 동일한 핑크 */
}

.chord-sheet.spring .lyrics {
    color: black;
}

.chord-sheet.spring .image-chord {
    filter: invert(19%) sepia(77%) saturate(3316%) hue-rotate(323deg) brightness(89%) contrast(96%);
}
