.chord-sheet.light {
    background-color: white;
    color: black;
}

/*.chord-sheet.light .verse {*/
/*    background-color: #f7fbf8; !* 벌스를 구분하기 위한 연한 배경색 *!*/
/*}*/

/*.chord-sheet.light .chorus {*/
/*    background-color: #fef5f5; !* 코러스를 구분하기 위한 연한 배경색 *!*/
/*}*/

/*.chord-sheet.light .bridge {*/
/*    background-color: #f7f8fd; !* 브릿지를 구분하기 위한 연한 배경색 *!*/
/*}*/

.chord-sheet.light .chord {
    color: darkgreen;
}

.chord-sheet.light .image-chord {
    filter: invert(15%) sepia(62%) saturate(2225%) hue-rotate(100deg) brightness(89%) contrast(106%);
}

.chord-sheet.light .image-chord--text {
    color: darkgreen;
}

.chord-sheet.light .lyrics {
    color: black;
}

/* 기타 라이트 테마 스타일 */
