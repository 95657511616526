.chord-sheet.winter {
    background-color: #F0F8FF; /* 아주 연한 파란색, 겨울의 차가운 느낌 */
    color: darkgreen;
}

/*.chord-sheet.winter .verse {*/
/*    background-color: rgba(255, 69, 58, 0.1); !* 따뜻한 빨간색, 연한 배경 *!*/
/*}*/

/*.chord-sheet.winter .chorus {*/
/*    background-color: rgba(34, 139, 34, 0.1); !* 어두운 초록색, 연한 배경 *!*/
/*}*/

/*.chord-sheet.winter .bridge {*/
/*    background-color: rgba(255, 228, 196, 0.1); !* 밝은 브라운, 연한 배경 *!*/
/*}*/

.chord-sheet.winter .chord {
    color: #B22222; /* 불타는 듯한 빨강색 */
}

.chord-sheet.winter .image-chord--text {
    color: #B22222; /* 동일한 빨강색 */
}

.chord-sheet.winter .lyrics {
    color: black;
}

.chord-sheet.winter .image-chord {
    filter: invert(20%) sepia(28%) saturate(4304%) hue-rotate(338deg) brightness(115%) contrast(105%);
}

/* 기타 겨울 테마 스타일 */
