.nav-item {
    margin-left: 60px;
}

@media (max-width: 600px) {
    .nav-item {
        margin-left: 40px;
    }
    .nav-item:first-child {
        margin-left: 0;
    }
}